export default [
  {
    title: '',
    index: 1,
    options: [
      {
        id: 1,
        label: 'Dashboard',
        to: 'dashboard',
        image: 'dashboard.png'
      },
      {
        id: 2,
        label: 'Productos',
        to: '/productos',
        image: 'productos.png',
        children: [
          {
            id: 1,
            label: 'Marcas',
            to: '/productos/marcas'
          },
          {
            id: 2,
            label: 'Líneas',
            to: '/productos/lineas'
          },
          {
            id: 3,
            label: 'Sectores',
            to: '/productos/sectores'
          }
        ]
      },
      {
        id: 3,
        label: 'Ventas',
        to: 'ventas',
        image: 'ventas.png'
      },
      {
        id: 14,
        label: 'Dolar',
        to: 'Dolar',
        image: 'dolar.svg'
      },
      {
        id: 4,
        label: 'Cotizaciones',
        to: 'cotizaciones',
        image: 'cotizacion.png'
      },
      {
        id: 5,
        label: 'Publicaciones',
        to: 'publicaciones',
        image: 'publicaciones.png'
      },
      {
        id: 6,
        label: 'Guía de selección',
        to: 'guias-de-seleccion',
        image: 'guias.png'
      },
      {
        id: 7,
        label: 'Preguntas frecuentes',
        to: 'preguntas-frecuentes',
        image: 'faqs.png'
      },
      {
        id: 9,
        label: 'Suscriptores',
        to: 'suscriptores',
        image: 'suscriptores.png'
      },
      {
        id: 10,
        label: 'Contacto',
        to: 'contacto',
        image: 'contacto.png'
      },
      {
        id: 14,
        label: 'Página Web',
        to: 'pagina-web',
        image: 'web_laptop.png'
      },
      {
        id: 11,
        label: 'Mensajes',
        to: 'mensajes',
        image: 'mensajes.png'
      },
      {
        id: 12,
        label: 'Usuarios',
        to: 'usuarios',
        image: 'usuarios.png'
      },
      {
        id: 13,
        label: 'Administradores',
        to: 'administradores',
        image: 'administradores.png'
      }
    ]
  }
]
